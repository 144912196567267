(function () {
  app.controller('WaxTrackerCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.wax_tracker_url = '';
      $scope.index_data_chart_url = '';
      $scope.sport = '';
      $scope.no_image_url = '';
      $scope.key_search = '';
      $scope.suggestions = [];
      $scope.is_visible_suggestions = false;
      $scope.current_focus = -1;
      $scope.sport = '';
      $scope.is_loading_active_losses = true;
      $scope.is_loading_active_gains = true;
      $scope.is_loading_recently_added = true;
      $scope.is_loading_recently_changed = true;
      $scope.is_loading_data = true;
      $scope.active_losses = [];
      $scope.active_gains = [];
      $scope.recently_added = [];
      $scope.recently_changed = [];
      $scope.per_pages = [10, 25, 50, 100];
      $scope.view = "list";
      $scope.page = 1;
      $scope.per_in_page = "10";
      $scope.sport_type = null;
      $scope.data = [];
      $scope.is_loading_more = false;
      $scope.total = null;
      $scope.is_more = false;
      $scope.filters = [];
      $scope.list_price = [
        {key: 'under_100', name: 'Under $100'},
        {key: '100_500', name: '$100-$500'},
        {key: 'over_500', name: 'Over $500'}
      ]
      $scope.range_price = {
        min: null,
        max: null,
        price_text: null
      }

      $scope.list_box = [];
      $scope.list_case = [];
      $scope.list_sport = [];
      $scope.price_text = null;
      $scope.params_release_date = "";
      $scope.loading = {
        is_loading_data : true
      }
      $scope.is_open = false;
      $scope.filter_list = [
        {name: 'All', title: 'All Wax', sport_type: null, box_type: null},
        {name: '📦 Wax Cases', title: 'All Wax Boxes', sport_type: null, box_type: 'box'},
        {name: '📦 Wax Boxes', title: 'All Wax Boxes', sport_type: null, box_type: 'case'},
        {name: '⚾ Baseball', title: 'All Baseball', sport_type: 'baseball', box_type: null},
        {name: '🏀 Basketball', title: 'All Basketball', sport_type: 'basketball', box_type: null},
        {name: '🏈 Football', title: 'All Football', sport_type: 'football', box_type: null},
        {name: '🏒 Hockey', title: 'All Hockey', sport_type: 'hockey', box_type: null},
        {name: '⚽ Soccer', title: 'All Soccer', sport_type: 'soccer', box_type: null},
        {name: '🤼 MMA', title: 'All MMA', sport_type: 'mixed-martial-arts', box_type: null},
        {name: '🤼 Wrestling', title: 'All Wrestling', sport_type: 'wrestling', box_type: null},
        {name: '🏁 Motorsport', title: 'All Motorsport', sport_type: 'motorsports', box_type: null},
        {name: '🐙 Pokemon', title: 'All Pokemon', sport_type: null, box_type: 'pokemon'},
        {name: '💯 Non-sport', title: 'All Non-sport', sport_type: 'non-sport', box_type: null}
      ];

      $scope.select_filter = $scope.filter_list[0];

      $scope.browseBySport = function() {
        if ($scope.sport  == '') {
          return;
        } else {
          window.location.href = `/waxtracker/sports/${$scope.sport}/all`;
        }
      };

      $scope.init = function () {};
      
      $scope.changeSearch = function () {
        if ($scope.key_search != '') {
          // $scope.getSuggestions();
        }
      }

      $scope.getSuggestions = function () {
        if ($scope.key_search != "") {
          $.ajax({
            beforeSend: function(){
              $(".loading").hide();
              $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
              $(".loading").attr('style', 'z-index: -1 !important');
            },
            url: $scope.waxtracker_suggestions_url,
            dataType: "json",
            cache: false,
            type: "GET",
            data: { q: $scope.key_search, sport: $scope.sport },
            afterSend: function(){},
            success: function (rs) {
              $timeout(function () {
                $scope.is_visible_suggestions = true;
                if (rs.succeed) {
                  $scope.suggestions = rs.data;
                  $scope.heightImg();
                } else {
                  
                  $scope.suggestions = [];
                }
                document.getElementById("autocomplete-list").scrollTop = 0;
                $scope.current_focus = -1;
              });
            }
          });
        } else {
          $scope.is_visible_suggestions = false;
          $scope.suggestions = [];
        }
      };
      
      $scope.selectItem = function (name) {
        $scope.key_search = name;
        $scope.is_visible_suggestions = true;
        $scope.current_focus = -1;
      }

      $scope.blurSearch = function() {
        $timeout(function() {
          $scope.is_visible_suggestions = false;
          $scope.current_focus = -1;
        }, 300)
      }

      $scope.keyupSearch = function(e) {
        if ($scope.key_search) {
          var f = document.getElementById("autocomplete-list");
          if (f) var x = f.getElementsByTagName("div");
          switch (e.keyCode) {
            case 40:
              $scope.current_focus++;
              $scope.addActive(x);
              $scope.scrollTop(f, x);
              break;

            case 38:
              $scope.current_focus--;
              $scope.addActive(x);
              $scope.scrollTop(f, x);
              break;
            
            case 13:
              if (!$scope.is_visible_suggestions && $scope.key_search != ""){
                $("#search-btn").click();
                break;
              }
              if (x[$scope.current_focus].getAttribute("title") == "No records found") break;
              $scope.key_search = x[$scope.current_focus].getAttribute("title");
              $scope.is_visible_suggestions = false;
              $scope.current_focus = -1;
              break;
          
            default:
              break;
          }
        }
      }

      $scope.addActive = function(x) {
        if (!x) return false;
        $scope.removeActive(x);
        if ($scope.current_focus >= x.length) $scope.current_focus = 0;
        if ($scope.current_focus < 0) $scope.current_focus = (x.length - 1);
        x[$scope.current_focus].classList.add("autocomplete-active");
      }

      $scope.removeActive = function(x) {
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("autocomplete-active");
        }
      }

      $scope.scrollTop = function(f, x) {
        let current_scroll_top = f.scrollTop,
            current_scroll_item = x[$scope.current_focus].scrollHeight * $scope.current_focus;
        if (
          (current_scroll_item > 306 && current_scroll_top + 306 < current_scroll_item) ||
          (current_scroll_top > current_scroll_item)
          ) {
          f.scrollTop = current_scroll_item;
        }
      }

      $scope.heightImg = function() {
        var cw = $('.img-suggestion').width();
        $('.img-suggestion').css({'height':cw+'px'});
      }

      $scope.getActiveGains = function() {
        $scope.is_loading_active_gains = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_active_gains_url,
          dataType: "json",
          cache: false,
          type: "GET",
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.is_loading_active_gains = false;
              if (rs.succeed) {
                $scope.active_gains = rs.data;
              } else {
                $scope.active_gains = [];
              }
            });
          }
        });
      }

      $scope.getActiveLosses = function() {
        $scope.is_loading_active_losses = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_active_losses_url,
          dataType: "json",
          cache: false,
          type: "GET",
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.is_loading_active_losses = false;
              if (rs.succeed) {
                $scope.active_losses = rs.data;
              } else {
                $scope.active_losses = [];
              }
            });
          }
        });
      }

      $scope.getRecentlyAdded = function() {
        $scope.is_loading_recently_added = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_recently_added_url,
          dataType: "json",
          cache: false,
          type: "GET",
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.is_loading_recently_added = false;
              if (rs.succeed) {
                $scope.recently_added = rs.data;
              } else {
                $scope.recently_added = [];
              }
            });
          }
        });
      }

      $scope.getRecentlyChanged = function() {
        $scope.is_loading_recently_changed = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_recently_changed_url,
          dataType: "json",
          cache: false,
          type: "GET",
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.is_loading_recently_changed = false;
              if (rs.succeed) {
                $scope.recently_changed = rs.data;
              } else {
                $scope.recently_changed = [];
              }
            });
          }
        });
      }

      $scope.getDataIndex = function() {
        var data = { 
          page: $scope.page,
          per_page: $scope.per_in_page, 
          box_type: $scope.box_type,
          sport_type: $scope.sport_type,
          list_case: $scope.list_case,
          list_sport: $scope.list_sport,
          list_box: $scope.list_box,
          to_date: $scope.to_date,
          from_date: $scope.from_date
        }
        if ($scope.range_price.min && $scope.range_price.max) {
          data.min_price = $scope.range_price.min;
          data.max_price = $scope.range_price.max;
        } else {
          if ($scope.price_text) data.price_text = $scope.price_text;
        }
        $scope.loading.is_loading_data = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_data_index_url,
          dataType: "json",
          cache: false,
          type: "POST",
          data: data,
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.loading.is_loading_data = false;
              $scope.is_loading_more = false;
              if (rs.succeed) {
                $scope.total = rs.data.total;
                $scope.data = rs.data.result;
                // $scope.date_release_title = rs.data.date_release_title;
                // $scope.getChart();
              } else {
                $scope.data = [];
              }
            });
          }
        });
      }

      $scope.getChart = function() {
        let ids = $scope.data.map(row => row.id);
        if (ids.length == 0)
          return;
        $.ajax({
          url: $scope.index_data_chart_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            ids: ids,
            selected_chart_option: '7_days',
            chart_type: 'price',
          },
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                Object.keys(rs.result).forEach(key => {
                  if (rs.result[key] == {}) {
                    return;
                  }
                  let data = rs.result[key].chart_data[0].data.sort().map(row => ({ x: row[0], y: row[1] }));
                  new Chart(`chart-${key}`,{
                    type: 'line',
                    data: {
                      datasets: [
                        {
                          data: data,
                          borderColor: rs.result[key].color,
                          pointRadius: 0,
                          poitnHoverRadius: 0,
                          hoverRadius: 0
                        }
                      ]
                    },
                    options: {
                      animation: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          enabled: false
                        }
                      },
                      scales: {
                        x: {
                          display: false
                        },
                        y: {
                          display: false
                        }
                      }
                    }
                  });
                });
              }
            });
          },
        });
      }

      $scope.getData = function() {
        $scope.page = 1;
        $scope.data = [];
        $scope.getDataIndex();
      }

      $scope.changeType = function(filter) {
        if (filter.box_type && !filter.sport_type) {
          $scope.box_type = filter.box_type;
          $scope.sport_type = null;
          $scope.params_release_date = `?box_type=${$scope.box_type}`;
        }

        if (!filter.box_type && filter.sport_type) {
          $scope.box_type = null;
          $scope.sport_type = filter.sport_type;
          $scope.params_release_date = `?sport_type=${$scope.sport_type}`;
        }

        if (!filter.box_type && !filter.sport_type) {
          $scope.box_type = null;
          $scope.sport_type = null;
          $scope.params_release_date = '';
        }
        $scope.select_filter = filter;
        $scope.defaultFilters();
        $scope.getData();
        $scope.close_menu();
      }

      $scope.favorite = function(box_id) {

      }

      $scope.next_page = function() {
        $scope.is_loading_more = true;
        $scope.to_date = $scope.data_next.to;
        $scope.from_date = $scope.data_next.from;
        $scope.getDataIndex();
      }

      $scope.previous_page = function() {
        $scope.is_loading_more = true;
        $scope.to_date = $scope.data_previous.to;
        $scope.from_date = $scope.data_previous.from;
        $scope.getDataIndex();
      }

      $scope.getFilters = function () {
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.waxtracker_get_filters_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {},
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.filters = rs.data;
              } else {
                $scope.filters = [];
              }
            });
          }
        });
      };
      
      $scope.changePrice = function (type) {
        switch (type) {
          case 'min':
            if ($.isNumeric($scope.range_price.min) && Number($scope.range_price.min) > 0) {
              if ($scope.range_price.max && Number($scope.range_price.max) < Number($scope.range_price.min)) {
                $scope.range_price.min = 0
              }
            } else {
              $scope.range_price.min = null
            }
            
            break;

          case 'max':
            if ($.isNumeric( $scope.range_price.max ) && Number($scope.range_price.max) > 0) {
              if ($scope.range_price.min && Number($scope.range_price.max) < Number($scope.range_price.min)) {
                $scope.range_price.min = 0
              }
            } else {
              $scope.range_price.max = null
            }
            
            break;
        }
      }

      $scope.filtersData = function () {
        $scope.list_box = $scope.filters.boxes.filter(b => b.checked).map(b => b.id);
        $scope.list_case = $scope.filters.cases.filter(b => b.checked).map(b => b.id);
        $scope.list_sport = $scope.filters.sports.filter(b => b.checked).map(b => b.id);
        $scope.price_text = $scope.range_price.price_text;
        $scope.box_type = $scope.sport_type = null;
        $scope.getData();
        $('#filtersModal').modal('hide');
      }

      $scope.defaultFilters = function () {
        $scope.list_box = $scope.list_case = $scope.list_sport = [];
        $scope.price_text = null;
        $scope.filters.boxes.forEach(element => {
          element.checked = false;
        });
        $scope.filters.cases.forEach(element => {
          element.checked = false;
        });
        $scope.filters.sports.forEach(element => {
          element.checked = false;
        });
        $scope.range_price = {
          min: null,
          max: null,
          price_text: null
        }
      }

      $scope.openFiltersModal = function () {
        // $scope.defaultFilters();
        $('#collapseBoxes').collapse('hide');
        $('#collapseCases').collapse('hide');
        $('#collapsePrice').collapse('hide');
        $('#collapseSport').collapse('hide');
        $('#filtersModal').modal('show');
      }

      $scope.recommendations = function () {
        $scope.is_loading_data = true;
        $.ajax({
          beforeSend: function(){
            $(".loading").hide();
            $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.recommendations_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            user_id: $scope.user_id,
            slug: $scope.slug_date
          },
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              $scope.is_loading_data = false;
              $scope.is_loading_more = false;
              if (rs.succeed) {
                $scope.total = rs.data.total;
                $scope.data = rs.data.result;
                $scope.date_release_title = rs.data.date_release_title;
                $scope.data_previous = rs.data.data_previous;
                $scope.data_next = rs.data.data_next;
              } else {
                $scope.data = [];
              }
            });
          }
        });
      }

      $scope.update_box_notification = function (box) {
        $.ajax({
          beforeSend: function(){
            // $(".loading").hide();
            // $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
            // $(".loading").attr('style', 'z-index: -1 !important');
          },
          url: $scope.update_box_notification_url,
          dataType: "json",
          cache: false,
          type: "GET",
          data: {
            box_id: box.id,
            user_id: $scope.user_id
          },
          afterSend: function(){},
          success: function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                toastr.success('Updated successfully');
                box.is_watchlist = !box.is_watchlist
              } else {
                toastr.error("An Unexpected Error Occurred!");
              }
            });
          }
        });
      }

      $scope.change_date = function (slug_date) {
        $scope.slug_date = slug_date;
        $scope.recommendations();
      }

      $scope.click_filter_sport = function () {
        $scope.is_open = !$scope.is_open;
        if ($scope.is_open) {
          $scope.open_menu();
        } else {
          $scope.close_menu();
        }
      }

      $scope.open_menu = function () {
        $scope.is_open = true;
        $('#content-filter-by-sport').addClass('show');
      }

      $scope.close_menu = function () {
        $scope.is_open = false;
        $scope.sub_data = {};
        $('#content-filter-by-sport').removeClass('show');
      }
    }
  ]);

}).call(this);
